$headerHeight: var(--padding-top-main-layout);
$tabBarHeight: var(--padding-bottom-main-layout);

$grid-large: 36px;
$grid-normal: 24px;
$grid-small: 16px;
$grid-mini: 8px;


// $browser-default-font-size: 55.2152px !default;
$browser-default-font-size: 58.5px !default;
html {
    font-size: $browser-default-font-size;
}
html, body {
    font-size: 14px;
    height: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@function rem($px) {
    @return $px / $browser-default-font-size * 1rem;
}

@function zh($px) {
    @return $px / $browser-default-font-size * 1rem;
}

@media screen {
    html {
        font-size:50px;
    }
}

@media screen and (min-width:320px) {
    html {
        font-size:50px;
    }
}
@media screen and (min-width:360px) {
    html {
        font-size:56.25px;
    }
}
@media screen and (min-width:375px) {
    html {
        font-size:58.5px;
    }
}

@media screen and (min-width:384px) {
    html {
        font-size:60px;
    }
}
@media screen and (min-width:393px) {
    html {
        font-size:61.4px;
    }
}
@media screen and (min-width:390px) {
    html {
        font-size:60.9375px;
    }
}
@media screen and (min-width:411px) {
    html {
        font-size:64.218px;
    }
}
@media screen and (min-width:412px) {
    html {
        font-size:64.375px;
    }
}
@media screen and (min-width:414px) {
    html {
        font-size:64.6875px;
    }
}
@media screen and (min-width:428px) {
    html {
        font-size:66.875px;
    }
}
@media screen and (min-width:435px) {
    html {
        font-size:67.9px;
    }
}
/*pc*/
@media screen and (min-width:640px) {
    html {
        font-size:100px;
    }
}
