@import "variable";

:root {
  --app-color-primary: #FF6600;
  --app-color-primary-dark: #CC5200;
  --app-color-primary-light: #FFB380;
}

.hairline-bottom {
  position: relative;
  &:after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid #EBEDF0;
    transform: scaleY(.5);
  }
}
.hairline-top {
  position: relative;
  &:before {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    right: 0;
    top: 0;
    left: 0;
    border-bottom: 1px solid #EBEDF0;
    transform: scaleY(.5);
  }
}
.hairline-left {
  position: relative;
  &:after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    left: 0;
    top: 0;
    bottom: 0;
    //height: 13.6vw%;
    border-left: 1px solid #EBEDF0;
    transform: scaleX(.5);
  }
}
.hairline-left-24 {
  position: relative;
  &:after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    left: 0;
    top: $grid-normal;
    bottom: $grid-normal;
    //height: 13.6vw%;
    border-left: 1px solid #EBEDF0;
    transform: scaleX(.5);
  }
}
.hairline-bottom-36 {
  position: relative;
  &:after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    right: $grid-large;
    bottom: 0;
    left: $grid-large;
    border-bottom: 1px solid #EBEDF0;
    transform: scaleY(.5);
  }
}
.hairline-bottom-24 {
  position: relative;
  &:after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    right: $grid-normal;
    bottom: 0;
    left: $grid-normal;
    border-bottom: 1px solid #EBEDF0;
    transform: scaleY(.5);
  }
}
.hairline-bottom-16 {
  position: relative;
  &:after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    right: $grid-small;
    bottom: 0;
    left: $grid-small;
    border-bottom: 1px solid #EBEDF0;
    transform: scaleY(.5);
  }
}

.vertical-line {
  position: relative;
  &:after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    right: 0;
    height: 100%;
    border-right: 1px solid #EBEDF0;
    transform: scale(.5);
  }
}

.spacing {
  margin: $grid-normal 0;
  &:first-child {
    //margin-top: 0;
  }
}

.touch-active {
  &:active {
    background: #F2F3F5!important;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.break {
  word-break: break-all;
  word-wrap: break-word;
}

.flex-start {
  align-items: flex-start!important;
  justify-content: flex-start!important;
}

.text-center {
  text-align: center;
}

.footer-fixed-button {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: $grid-large 60px;
  z-index: 1;
  .th-button {
    width: 100%;
  }
}

/* 列表item样式 */
.common-list-item {
  background: #FFFFFF;
  border-radius: 10px;
  margin-top: $grid-normal;
  box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.04);
  &:first-child {
    margin-top: 0;
  }
  .common-list-item-header {
    padding: $grid-normal;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #AA66000A;
    .index {
      font-size: 24px;
      color: #646566;
    }
    .number {
      font-size: 26px;
      color: #323233;
    }
  }
  .common-list-item-body {
    padding: $grid-normal;
    position: relative;
    .common-list-item-status {
      position: absolute;
      top: $grid-normal;
      right: $grid-normal;
      color: #FF6600;
      font-weight: 600;
      font-size: 24px;
    }
    .common-list-item-body-row {
      display: flex;
      align-items: center;
      font-size: 24px;
      color: #323233;
      line-height: 36px;
      .common-list-item-body-label {
        width: 180px;
      }
      .common-list-item-body-value {
        flex: 1;
        width: 0;
      }
    }
  }
  .common-list-item-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: $grid-normal;
  }
}
