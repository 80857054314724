@font-face {
  font-family: "iconfont"; /* Project id 3500841 */
  src: url('iconfont.woff2?t=1656992384642') format('woff2'),
       url('iconfont.woff?t=1656992384642') format('woff'),
       url('iconfont.ttf?t=1656992384642') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-pending:before {
  content: "\e696";
}

.icon-mine:before {
  content: "\e6b1";
}

.icon-workbench:before {
  content: "\e6cb";
}

