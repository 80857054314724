@import "src/assets/styles/variable";

#__main_layout {
  min-height: 100vh;
  background: #F7F8FA;
  .main {
    padding-top: $headerHeight;
    // padding-bottom: $tabBarHeight;
    //overflow-y: auto;
    .suspense-loading {
      color: #FF6600;
      //background: linear-gradient(135deg, #FF6600 0%, #FF993C 98%);
      background: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100vw;
      height: 100vh;
      font-size: 32px;
      font-family: Avenir, Helvetica, Arial, sans-serif;
      letter-spacing: 10px;
      text-transform: uppercase;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1314;
      span {
        animation: shrink 2s infinite;
      }
      @keyframes shrink {
        50% {
          transform: scale(0);
        }
      }
    }
  }
  .nav-bar {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 520;
    .nav-bar-content {
      height: $headerHeight;
      width: 100%;
      z-index: 520;
      position: relative;
      display: flex;
      align-items: center;
      line-height: 1;
      background: #FFFFFF;
      user-select: none;
      .nav-bar-left {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        padding: 0 $grid-normal;
        display: flex;
        align-items: center;
        color: #646566;
        font-size: 28px;
      }
      .nav-bar-title {
        margin: 0 auto;
        max-width: 60%;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #323233;
        font-size: 32px;
      }
      .nav-bar-right {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 100%;
        padding: 0 $grid-normal
      ;
        display: flex;
        align-items: center;
      }
    }
  }
  .tab-bar {
    display: none;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #FFFFFF;
    z-index: 520;
    .tab-bar-content {
      height: $tabBarHeight;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 1;
      font-size: 14px;
      .tab-bar-item {
        flex: 1;
        //padding: 0 $grid-normal;
        //text-align: center;
        //font-size: 24px;
        padding: $grid-small 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        .iconfont {
          font-size: 40px;
          //font-weight: 600;
        }
      }
    }
  }
}


